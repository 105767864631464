<template>
  <div>
    <el-dialog
      :title="title + '管理员'"
      :visible.sync="showing"
      width="550px"
      min-height="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="hide"
    >
      <div v-loading="loading">
        <div class="banben">
          <div class="bandanItem">
            <div>账号</div>
            <input
              ref="code"
              v-model="formInline.code"
              :disabled="disabled"
              placeholder="请输入账号"
              maxlength="12"
              type="text"
            />
          </div>
          <div class="bandanItem">
            <div>有效期</div>
            <el-date-picker
              v-model="formInline.validityTime"
              type="date"
              ref="validityTime"
              placeholder="请输入有效期"
              style="width: 100%; position: relative"
            ></el-date-picker>
            <img src="@/assets/ic_calendar.png" alt class="calendar" />
          </div>
        </div>
        <div class="banben" v-if="title == '创建'">
          <div class="bandanItem">
            <div>密码</div>
            <input
              ref="pass"
              v-model="formInline.pass"
              placeholder="请输入密码"
              maxlength="16"
              minlength="6"
              type="text"
            />
          </div>
          <div class="bandanItem">
            <div>确认密码</div>
            <input
              ref="surepass"
              v-model="formInline.surepass"
              maxlength="16"
              minlength="6"
              placeholder="请确认密码"
              type="text"
            />
          </div>
        </div>
        <div class="banben">
          <div class="bandanItem">
            <div>姓名</div>
            <input
              ref="name"
              v-model="formInline.name"
              placeholder="请输入姓名"
              maxlength="10"
              minlength="2"
              type="text"
            />
          </div>
          <div class="bandanItem">
            <div>手机号</div>
            <input
              ref="phone"
              v-model="formInline.phone"
              placeholder="请输入手机号"
              maxlength="11"
              minlength="11"
              type="text"
            />
          </div>
        </div>
        <div class="banbentwo">
          <div class="banbentwoItem">
            <div>公司名称</div>
            <input
              ref="company"
              v-model="formInline.company"
              placeholder="请输入公司名称"
              maxlength="16"
              minlength="4"
              type="text"
            />
          </div>
        </div>
        <div class="banben">
          <div class="bandanItem">
            <div>部门</div>
            <input
              ref="department"
              v-model="formInline.department"
              placeholder="请输入部门"
              maxlength="8"
              minlength="3"
              type="text"
            />
          </div>
          <div class="bandanItem">
            <div>职位</div>
            <input
              ref="post"
              v-model="formInline.post"
              placeholder="请输入职位"
              maxlength="8"
              type="text"
            />
          </div>
        </div>
        <div class="sure">
          <el-button
            size="small"
            type="primary"
            @click="createStorage('formInline')"
            >确定</el-button
          >
        </div>
        <!-- </el-form> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getRequest, postRequest } from "@/api/api.js";
import { regMatch } from "../../utils/reg";
export default {
  data() {
    return {
      //审核弹窗
      title: "",
      showing: false,
      loading: false,
      bedisabled: false,
      index: null,
      disabled: false,
      //表单
      formInline: {
        status: 0, //状态
      },
      list: [],
    };
  },
  props: ["state"],
  methods: {
    hide() {
      this.fileListUpload = [];
      this.showing = false;
    },
    show(item, val, index) {
      this.title = item;
      this.showing = true;
      this.formInline = {
        status: 0, //状态
      };
      if (item == "编辑") {
        val.status = Number(val.status);
        this.formInline = JSON.parse(JSON.stringify(val));
        this.index = index;
        this.disabled = true;
      }
    },
    createStorage(formData) {
      // 表单验证-----------
      // this.$refs[formData].validate((valid) => {
      //   if (valid) {
      if (!regMatch(this.formInline.code, "账号")) {
        this.$refs.code.focus();
        return;
      }
      if (!regMatch(this.formInline.validityTime, "有效期")) {
        this.$refs.validityTime.focus();
        return;
      }
      if (!regMatch(this.formInline.pass, "密码")) {
        this.$refs.pass.focus();
        return;
      }

      if (!regMatch(this.formInline.name, "姓名")) {
        this.$refs.name.focus();
        return;
      }
      if (!regMatch(this.formInline.phone, "手机号")) {
        this.$refs.phone.focus();
        return;
      }
      const regMobile = /^1[34578]\d{9}$/;
      if (!regMobile.test(this.formInline.phone)) {
        this.$message({
          duration: 5000,
          message: "请填写手机号正确形式！",
          type: "warning",
        });
        this.$refs.phone.focus();
        return;
      }
      if (!regMatch(this.formInline.company, "公司名称")) {
        this.$refs.company.focus();
        return;
      }
      if (!regMatch(this.formInline.department, "部门")) {
        this.$refs.department.focus();
        return;
      }
      if (!regMatch(this.formInline.post, "职位")) {
        this.$refs.post.focus();
        return;
      }
      if (this.title == "创建") {
        if (!regMatch(this.formInline.surepass, "确认密码")) {
          this.$refs.surepass.focus();
          return;
        }
        if (this.formInline.surepass != this.formInline.pass) {
          this.$message({
            duration: 5000,
            message: "请填写两次密码不一致！",
            type: "warning",
          });
          this.$refs.surepass.focus();
          return;
        }
        let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
        postRequest("/addSysUser", this.formInline).then((res) => {
          this.$nextTick(() => {
            loadingInstance.close();
          }); //不回调
          if (res.status == 200) {
            this.$message({
              duration: 5000,
              message: "新增成功!",
              type: "success",
            });
            this.hide();
            this.$parent.getFounderList();
          } else {
            this.$message({
              duration: 5000,
              message: res.message,
              type: "error",
              hasClose: true,
            });
          }
        });
      } else {
        this.updateStation(); //调用编辑函数
      }
    },
    // 编辑
    updateStation() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("/updateSysUser", this.formInline).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        }); //不回调
        if (res.status == 200) {
          this.$message({
            duration: 5000,
            message: "修改成功!",
            type: "success",
          });
          this.hide();
          this.$parent.getFounderList();
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "error",
            hasClose: true,
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.el-form-item__label {
  display: block !important;
  width: 100% !important;
}
.el-radio-group {
  width: 100%;
}
.el-button {
  width: 320px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  margin-top: 32px;
}
.el-button:hover {
  transform: scale(1.1);
}
.sure {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #d8d8d8;
}
/*  */
.banben {
  display: flex;
  justify-content: space-between;
}
.banbentwo {
  width: 100%;
}
.banbentwoItem {
  width: 100%;
  margin-bottom: 16px;
}
.banbentwoItem > input {
  width: 100%;
  height: 40px !important;
  background: #fefeff;
  box-sizing: border-box;
  border: 1px solid #e8ecef;
  border-radius: 4px;
  padding-left: 15px;
  outline: none;
}
.bandanItem > select {
  width: 100%;
  height: 40px !important;
  background: #fefeff;
  box-sizing: border-box;
  border: 1px solid #e8ecef;
  border-radius: 4px;
  padding-left: 15px;
  outline: none;
}
.banbentwoItem > div:first-child {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #000000;
  opacity: 0.65;
}
.el-input__prefix {
  display: none;
}

.calendar {
  position: absolute;
  right: 28px;
  top: 124px;
}

.bandanItem {
  display: flex;
  flex-direction: column;
  width: 242px;
  height: 74px;
  margin-bottom: 16px;
}
.bandanItem > div {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #000000;
  opacity: 0.65;
}
.bandanItem > input {
  width: 100%;
  height: 40px !important;
  background: #fefeff;
  box-sizing: border-box;
  border: 1px solid #e8ecef;
  border-radius: 4px;
  padding-left: 15px;
  outline: none;
}
.bandanItem > .el-date-picker {
  width: 100%;
  height: 40px;
}
.el-input--prefix .el-input__inner {
  border: 1px solid #e8ecef !important;
  padding: 0 15px;
  color: #000;
}
.el-radio__inner {
  height: 24px !important;
  width: 24px !important;
}
.el-radio__inner::after {
  height: 10px;
  width: 10px;
}
input {
  opacity: 0.65;
}
</style>