<template>
  <div class="outer">
    <div class="hl-header">
      <div class="hl-search">
        <div class="hl-title">系统管理员</div>
        <div class="search">
          <el-input
            v-model="condition.name"
            placeholder="请输入搜索内容"
            class="search-box"
            maxlength="12"
            prefix-icon="el-icon-search"
            clearable
            @blur="getFounderList"
          ></el-input>
          <el-button
            v-if="chuangjian"
            type="primary"
            @click="addIdType(0, null)"
            class="addIdType"
            >+ 创建管理员</el-button
          >
        </div>
      </div>
    </div>
    <div class="hl-content">
      <div class="mokuai">管理员列表</div>
      <el-table
        class="hl-table"
        :data="tableData"
        height="90%"
        :header-cell-style="{ background: '#F4F5F9', color: '#000000' }"
      >
        <el-table-column label="账号" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.code }}</span>
          </template>
        </el-table-column>
        <el-table-column label="姓名" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="手机号" width="180">
          <template slot-scope="scope">
            <span>{{ scope.row.phone }}</span>
          </template>
        </el-table-column>
        <el-table-column label="公司" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.company }}</span>
          </template>
        </el-table-column>
        <el-table-column label="部门" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.department }}</span>
          </template>
        </el-table-column>
        <el-table-column label="职位" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.post }}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="120">
          <template slot-scope="scope">
            <div class="zt">
              <div v-if="scope.row.status == 0">
                <span class="qiyong"></span>
                <span>启动</span>
              </div>
              <div v-else>
                <span class="jinyong"></span>
                <span>禁用</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="禁用说明">
          <template slot-scope="scope">
            <span>{{ scope.row.statusRemark }}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="260">
          <template slot-scope="scope">
            <div class="cz">
              <div class="qidong" v-if="shanchu">
                <el-button
                  type="text"
                  @click="qidong(scope.row)"
                  v-if="scope.row.status != 0 && shanchu"
                  >启动</el-button
                >
                <el-button
                  v-else-if="shanchu"
                  type="text"
                  @click="toDisable(scope.$index, scope.row)"
                  style="color: #f5222d"
                  >禁用</el-button
                >
                <span class="line"></span>
                <el-button
                  v-if="xiugai"
                  type="text"
                  @click="edit(scope.$index, scope.row)"
                  >编辑</el-button
                >
              </div>
              <el-button
                v-if="czmm"
                class="cz-button"
                size="mini"
                @click="reset(scope.$index, scope.row)"
                >重置密码</el-button
              >
              <el-button
                v-if="qxsz"
                class="qx-button"
                size="mini"
                @click="manage(scope.row)"
                >权限设置</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          @size-change="onPaginationSize"
          @current-change="onPaginationIndex"
          :current-page="condition.page"
          :page-size="10"
          :page-sizes="[10, 20, 30, 40]"
          layout="prev,pager,sizes,next,jumper,total"
          :total="totolNums"
        ></el-pagination>
      </div>

      <!-- 系统管理员权限设置 -->
      <el-dialog
        title="权限分配"
        :visible.sync="dialogVisible"
        width="500px"
        height="720px"
      >
        <!-- 大盒子 -->
        <div class="big_box">
          <!-- 左边盒子 -->
          <div class="left_box">
            <div class="header_box">
              <div class="jurisdiction">权限模块</div>
              <div class="select" @click="checkAllBtn()">全选</div>
            </div>
            <div class="content">
              <div
                class="bottom"
                v-for="(item, index) in mode"
                :key="index.code"
              >
                <el-checkbox
                  @change="changemodecheck($event, item, index)"
                  v-model="item.checked"
                ></el-checkbox>
                <span class="spname" @click="changemode(index)"
                  >{{ item.name }}[{{ item.code }}]</span
                >
              </div>
            </div>
          </div>
          <!-- 右边盒子 -->
          <div class="right_box">
            <div class="header_box">
              <div class="jurisdiction">权限选择</div>
              <div class="select" @click="checkAllBtn1()">全选</div>
            </div>
            <div class="content">
              <div
                class="bottom"
                v-for="(item, index2) in choosepower"
                :key="index2.code"
              >
                <el-checkbox v-model="item.checked"></el-checkbox>
                <span class="spname">{{ item.name }}[{{ item.code }}]</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 横线 -->
        <div class="Horizontal"></div>
        <!-- 确定按钮 -->
        <span slot="footer">
          <div type="primary" class="btn" @click="determine()">
            <span>确 定</span>
          </div>
        </span>
      </el-dialog>
    </div>

    <addModify ref="addModify" />
    <resetModify ref="resetModify" />
    <disableModify ref="disableModify" />
  </div>
</template>
<script>
import { getRequest, postRequest } from "@/api/api.js";
import addModify from "@/views/guanliyuan/addModify.vue";
import resetModify from "@/views/guanliyuan/resetModify.vue";
import disableModify from "@/views/guanliyuan/disableModify.vue";

export default {
  data() {
    return {
      tableData: [],
      list: [],
      checked: true,
      input: "",
      totolNums: null,
      dialogVisible: false,
      userCode: "",
      checkAll: false,
      mode: [], //存储的权限数据
      choosepower: [], //存储的权限数据
      condition: {
        page: 1,
        num: 10,
      },
      chuangjian: false,
      xiugai: false,
      shanchu: false,
      czmm: false,
      qxsz: false,
    };
  },
  components: {
    addModify,
    resetModify,
    disableModify,
  },
  created() {
    if (!this.power.checkedpower("00301")) {
      //在PMS管理系统里面-获取权限
      this.$router.push("power");
    }
    // 创建
    this.chuangjian = this.power.checkedpower("00302");
    // 修改
    this.xiugai = this.power.checkedpower("00303");
    // 删除，禁用
    this.shanchu = this.power.checkedpower("00304");

    // 重置密码
    this.czmm = this.power.checkedpower("00305");
    // 权限设置
    this.qxsz = this.power.checkedpower("00306");
    this.getFounderList();
  },
  methods: {
    changemodecheck(event, item, chooseindex) {
      this.mode.forEach((item, index) => {
        if (chooseindex == index) {
          this.changemode(chooseindex);
          item.checked = event;
          this.mode[index].list.forEach((item1) => {
            item1.checked = event;
          });
        }
      });
      console.log(event);
      console.log(item, chooseindex);
    },
    changemode(index) {
      this.choosepower = this.mode[index].list;
    },
    checkAllBtn() {
      this.mode.forEach((item, index) => {
        item.checked = true;
        this.mode[index].list.forEach((item1) => {
          item1.checked = true;
        });
      });
    },
    checkAllBtn1() {
      this.choosepower.forEach((item, index) => {
        item.checked = true;
      });
    },
    // 获取系统管理员列表
    getFounderList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectAllSysUser", this.condition).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        }); //不回调
        if (res.status == 200) {
          //alert(111)
          this.tableData = res.data;
          this.totolNums = res.count;
          console.log(this.totolNums);
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "error",
            hasClose: true,
          }); //如果无法获取这一条数据，就报错
        }
      });
    },
    // 权限设置
    manage(user) {
      this.dialogVisible = true;
      let loadingInstance = this.$loading({ text: "权限加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectSysModeSysPower").then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        }); //不回调
        if (res.status == 200) {
          res.data.forEach((item, index) => {
            item.checked = false;
            res.data[index].list.forEach((item1, index1) => {
              item1.checked = false;
              item1.userCode = user.code;
              item1.powerCode = item1.code;
            });
          });
          this.mode = res.data;
          this.choosepower = res.data[0].list;
          //获取当前人员权限
          this.getUserPower(user);
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "error",
            hasClose: true,
          });
        }
      });
    },
    getUserPower(user) {
      let postData = {
        userCode: user.code,
        offset: 1,
        num: 1000,
      };
      postRequest("selectSysUserPower", postData).then((res) => {
        if (res.status == 200) {
          var mypower = res.data.data;
          for (var j = 0; j < this.mode.length; j++) {
            for (var t = 0; t < this.mode[j].list.length; t++) {
              for (var i = 0; i < mypower.length; i++) {
                console.log(
                  "quanxian",
                  this.mode[j].list[t].code + "??" + mypower[i].powerCode
                );
                if (this.mode[j].list[t].code == mypower[i].powerCode) {
                  //用有此权限
                  this.mode[j].checked = true;
                  this.mode[j].list[t].checked = true;
                  break;
                }
              }
            }
          }
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "error",
            hasClose: true,
          });
        }
      });
    },
    // 确定按钮
    determine(date) {
      let loadingInstance = this.$loading({ text: "正在设置权限..." }); //如果这数据不存在，则要去接口请求数据
      let powerList = [];
      this.mode.forEach((item, index) => {
        this.mode[index].list.forEach((item1) => {
          if (item1.checked == true) {
            powerList.push(item1);
          }
        });
      });
      if (powerList.length < 0) {
        this.$message({
          duration: 5000,
          message: "您未分配权限",
          type: "error",
          hasClose: true,
        });
        return;
      }
      console.log("已选权限", powerList);
      postRequest("updateSysUserPower", powerList).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        }); //不回调
        if (res.status == 200) {
          this.dialogVisible = false;
          this.$message({
            duration: 5000,
            message: "权限设置成功",
            type: "success",
            hasClose: true,
          });
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "error",
            hasClose: true,
          });
        }
      });

      this.dialogVisible = false;
    },
    // 权限模块全选
    selectall() {
      console.log(123);
    },
    search() {},
    // 新增
    addIdType() {
      let state = "创建";
      this.$refs.addModify.show(state);
    },
    // 修改
    edit(index, row) {
      let state = "编辑";
      console.log(index);
      this.$refs.addModify.show(state, row, index);
    },
    qidong(row) {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {
        status: 0,
        code: row.code,
        statusRemark: "", //禁用说明 必须
      };
      postRequest("/stopSysUser", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.$message({
            duration: 5000,
            message: "启动成功!",
            type: "success",
          });
          this.getFounderList();
        } else {
          this.$mymessage({
            message: res.message,
            type: "error",
            hasClose: true,
          });
        }
      });
    },
    // 禁用
    toDisable(index, row) {
      let state = "禁用";
      console.log(index);
      this.$refs.disableModify.show(state, row, index);
    },
    // 重置密码
    reset(index, row) {
      let state = "重置密码";
      this.$refs.resetModify.show(state, row, index);
    },
    changePage(val) {
      this.condition.page = val;
      this.getFounderList();
    },
    // 每页的数据数
    onPaginationSize(val) {
      this.condition.num = val;
      this.getFounderList();
    },
    // 页数
    onPaginationIndex(val) {
      this.condition.offset = val;
      this.getFounderList();
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.outer {
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.button {
  width: 60px;
  height: 30px;
  background-color: cornflowerblue;
  color: white;
  border: none;
}
.button2 {
  background-color: white;
  border: 1px solid #999;
  color: #666;
}
.hl-header {
  width: 100%;
  height: 60px;
  display: flex;
}
.hl-search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  position: relative;
}
.hl-title {
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.85);
}
.addIdType {
  width: 136px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
}
.hl-search .span {
  width: 40px;
  height: 30px;
  font-size: 14px;
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 14px;
  border: none;
}
.hl-content {
  flex: 1;
  background-color: #fff;
  padding: 21px 32px;
  position: relative;
}
.mokuai {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  opacity: 0.85;
  margin-bottom: 21px;
}
.hl-table {
  /* background: red; */
  overflow: auto;
}
.cz-button {
  width: 74px;
  height: 28px;
  background: #fe7c4b;
  opacity: 1;
  border-radius: 4px;
  color: #fff;
}
.qx-button {
  width: 74px;
  height: 28px;
  background: #6ec543;
  opacity: 1;
  border-radius: 4px;
  color: #fff;
}
.qidong {
  width: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 24px;
}
.line {
  display: inline-block;
  height: 14px;
  border: 1px solid #e8ecef;
}
.cz {
  display: flex;
}
.block {
  position: absolute;
  bottom: 2px;
  right: 32px;
}
.qiyong {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #387dff;
  border-radius: 50%;
  margin-right: 6px;
}
.jinyong {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #f5222d;
  border-radius: 50%;
  margin-right: 6px;
}
.zt > div {
  display: flex;
  align-items: center;
}
.search {
  display: flex;
}
.search-box {
  margin-right: 23px;
  width: 272px;
}
.el-button {
  display: inline-block;
  line-height: 2 !important;
  width: 100px !important;
}

.btn {
  width: 200px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  margin: 0 auto;
  text-align: center;
}
.btn > span {
  width: 33px;
  height: 22px;
  font-size: 16px;
  font-weight: bold;
  line-height: 40px;
  color: #ffffff;
}

.big_box {
  display: flex;
  justify-content: space-around;
  height: 450px;
}
.left_box {
  width: 205px;
  height: 100%;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 4px 4px 0px 0px;
  overflow: hidden;
  overflow-y: scroll;
  white-space: nowrap;
}
.left_box ::-webkit-scrollbar {
  width: 0 !important;
}

.left_box::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
.right_box ::-webkit-scrollbar {
  width: 0 !important;
}

.right_box::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
.right_box {
  width: 205px;
  height: 100%;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 4px 4px 0px 0px;
  overflow: hidden;
  overflow-y: scroll;
  white-space: nowrap;
}
.header_box {
  display: flex;
  justify-content: space-between;
  padding: 11px 16px;
  width: 204px;
  height: 44px;
  background: #fafafa;
  border-bottom: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
  border-radius: 3px 3px 0px 0px;
  position: fixed;
  z-index: 1000;
}
.jurisdiction {
  width: 64px;
  height: 22px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
}
.select {
  width: 28px;
  height: 20px;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  color: #387dff;
  cursor: pointer;
}
.select:hover {
  transform: scale(1.1);
}
.bottom {
  margin-left: 16px;
  margin-top: 13px;
}
.Horizontal {
  width: 97%;
  height: 1px;
  background: #e8ecef;
  border-radius: 0px;
  margin: 0 auto;
  margin-top: 32px;
}
.content {
  margin-top: 50px;
}
.spname {
  margin-left: 10px;
  cursor: pointer;
  display: inline-block;
}
</style>